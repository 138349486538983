<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    class="bg-secondary text--black"
    v-bind="$attrs"
    :key="$store.state.keyDrawer"
  >
    
    <v-img
      :src="profile.imgAvatar"
      style="height: 70px; width: 70px"
      class="align-self-center mx-auto mt-2"
    />

    

    <p class="ma-0 custom-text text-center mx-4" >
      Asociación de Concesionarios de<br />Automotores de la R.A.</p>
      



    


    <div
      v-if="$store.state.drawerLoading || $store.state.drawerLoadingAdmin"
      class="d-flex justify-center align-center"
      style="height: 300px"
    >
      <v-progress-circular indeterminate color="primary"><br><small class="text-center" style="margin-top:105px !important">obteniendo datos...</small></v-progress-circular>
    </div>
    <v-list expand nav class="text--black" v-else>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <v-list expand nav>
        <template v-for="(item, i) in items">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
          </base-item-group>
          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
      </v-list>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <div v-if="$store.state.user.emule === true"
      class="d-flex flex-column justify-end align-center"
      style="height: 200px"
    >
      <!-- Icono y texto "Mi Perfil" arriba del botón -->
      <div class="d-flex flex-column align-center mb-3">
        <v-icon style="font-size: 50px; color: black;">mdi-account-circle</v-icon>
        <span class="mt-1 ml-1" style="font-size: 12px; font-weight: 500; color: black; text-transform: uppercase;">{{ $store.state.user.userInfoAdmin.username +" | "+$store.state.user.userInfoAdmin.firstName +" "+ $store.state.user.userInfoAdmin.lastName }}</span>
      </div>

      <!-- Botón de salir emulación -->
      <v-btn

        @click="dialogLogoutEmulacion"
        class="custom-btn capitalize-first"
        color="white"
      >
        <div class="ml-2" style="color: red;">
          <v-icon style="font-size: 20px; color: red;">mdi-logout</v-icon>
        </div>
        <span class="custom-btn capitalize-first" style="font-size: 12px; font-weight: 500; color: red;">&nbsp; &nbsp; Salir emulación</span>
      </v-btn>
    </div>







    <dialog-confirmar
        v-model="dialogs['logoutEmulacion']"
        @input="dialog = $event"
        title="Finalizar emulación?"
        info="¿Desea finalizar la emulacion del usuario?"
        icon="mdi-logout"
        color="#F44336"

        @confirm="confirmLogoutEmule()"
      />

  </v-navigation-drawer>
</template>

<style>
.custom-text {
  font-size: 12px;
}
</style>

<script>
// Utilities
import { mapState, mapActions } from "vuex";
import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
//import { mapActions } from "vuex";

export default {
  name: "DashboardCoreDrawer",
components:{
  DialogConfirmar
},
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dialogs: {
        logoutEmulacion: false,
      },
    items: [
      {
        icon: "mdi-view-dashboard mt-4",
        title: "Dashboard",
        to: "",
      },
    ],
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {},

    profile() {
      return {
        avatar: true,
        imgAvatar: "/img/acara_logo_2.png",
        title: this.$store.state.user.username,
        //title: this.$keycloak.tokenParsed.preferred_username,
        //subtitle: "MATRICE LOGIN",
        subtitle: this.$appName,
        //role: "Perfil:" + this.$store.state.user.roles,
        role: this.$store.state.user.name,
      };
    },
  },
  created() {

    
    

    if (this.$store.state.user.sapProperties.length > 0) {
      if (this.$store.state.user.sapProperties.U_BloqueoWeb == "tNO") {
        this.setNavDrawer();
      }
    }
    //cuando todos los usuarios esten en sap, hay que comentar esta opcion
    else {
      this.setNavDrawer();
    }

    if(this.$store.state.user.emule === true)
    {
      //para cambiar color de fondo
      document.documentElement.style.setProperty("--bs-secondary", "#eee");
    }
  },
  methods: {
    ...mapActions({
      finalizarEmulacion: "logoutEmule",
    }),
    dialogLogoutEmulacion() {
      this.dialogs.logoutEmulacion = true;
    },
    async confirmLogoutEmule(){
      try{
        Swal.alertGetInfo("Finalizando Emulación");
        this.finalizarEmulacion();
        await this.$delay(3000);
        Swal.close();
        window.location.href = '/';

      }
      catch(e){
        console.log(e);
        Swal.close();
      }


    },
    setNavDrawer() {

      this.items = [
        {
        icon: "mdi-view-dashboard mt-4",
        title: "Dashboard",
        to: "/",
      },
      ];








      let childrensComprar = Array();




      if (
        this.$canSap("comprarElementosRegistrales") ||
        this.$canSap("comprarElementosAduana")||
        this.$canSap("comprarAdquirirSaldo")
      )
      {

        if (this.$canSap("comprarElementosRegistrales")) {
          childrensComprar.push({
            icon: "",
            title: "Comprar Elementos Registrales",
            to: "elementos_registrales",
          });
        }
        //acceso a privs de elementos_aduana

        if (this.$canSap("comprarElementosAduana")) {
          childrensComprar.push({
            icon: "",
            title: "Comprar Elementos Aduana",
            to: "elementos_aduana_compra",
          });
        }

        if (this.$canSap("comprarAdquirirSaldo")) {
          childrensComprar.push({
            /*icon: "mdi-bank",*/
            icon: "",
            title: "Adquirir saldo en cta cte",
            to: "adquirir_saldo_cta_cte",
          });
        }

        this.items.push({
          title: "Comprar",
          icon: "mdi-comprar my-0 py-0",
          group: "",
          children: childrensComprar,
        });
        this.items.map(this.mapItem);
      }


       let childrensVender = Array();

      if (
         this.$canSap("venderElementosAduana")  ||
        this.$canSap("venderElementosAnmac")

      ) {
                if ( this.$canSap("venderElementosAduana")) {
                  childrensVender.push({
                    icon: "mdi-cart",
                    title: "Vender Elementos Aduana",
                    to: "elementos_aduana_venta",
                  });
                }

                if (this.$canSap("venderElementosAnmac")  ) {
                  childrensVender.push({
                    icon: "mdi-cart",
                    title: "Vender Elementos ANMAC",
                    to: "elementos_anmac_venta",
                  });
                }


                this.items.push({
                  title: "Vender",
                  icon: "mdi-comprar my-0 py-0",
                  group: "",
                  children: childrensVender,
                });
                this.items.map(this.mapItem);
      }




      let childrenCtaCte = Array();

       if ( this.$canSap("miCuentaCorriente") ) {


        if ( this.$canSap("miCuentaCorriente") ) {
          /*childrenCtaCte.push({

            icon: "",
            title: "Consultar Cuenta Corriente",
            to: "cuenta_corriente_consulta",
          });*/
          childrenCtaCte.push({
            icon: "",
            title: "Consultar",
            to: "mi_cuenta_corriente_consulta",
          });
        }

        this.items.push({
          title: "Cuenta Corriente",
          icon: "",
          group: "",
          children: childrenCtaCte,
        });
        this.items.map(this.mapItem);

      }

      let childrenTransacciones = Array();

       if ( this.$canSap("planillaDeCaja") || this.$canSap("listadoTransacciones") || this.$can(["admin_general"] )) {

            if ( this.$canSap("listadoTransacciones"))
            {
                childrenTransacciones.push({
                  icon: "",
                  title: "Listado de Transacciones",
                  to: "transacciones",
                });
            }


             if ( this.$canSap("planillaDeCaja") || this.$can(["admin_general"])) {
                  childrenTransacciones.push({
                     icon: "",
                     title: "Planilla de Caja",
                     to: "planilla_caja",
                   });
             }

            this.items.push({
              title: "Transacciones",
              icon: "",
              group: "",
              children: childrenTransacciones,
            });
      }

      this.items.map(this.mapItem);

      if (
        this.$canSap("venderElementosAnmac")
      ) {
        let childrens = Array();
        childrens.push({
          title: "Gestión de Stock",
          to: "estampillas",
          icon: "mdi mdi-database",
        });
        this.items.push({
          title: "Stock",
          icon: "",
          group: "/stock",
          children: childrens,
        });
        this.items.map(this.mapItem);
      }


         if ( this.$can(["admin_general"])) {
          let childrens = Array();


          childrens.push({
            title: "Clientes Aduana",
            icon: "",
            group: "/aduana",
            to: "ClientesAduana",
          });
           childrens.push({
            title: "Clientes ANMAC",
            icon: "",
            group: "/anmac",
            to: "ClientesAnmac",
          });


          this.items.push({
            title: "Gestión de Clientes",
            icon: "",
            group: "/clientes",
            children: childrens,
          });

          this.items.map(this.mapItem);
      }
      if ( this.$can(["admin_general"])) {
          let childrens = Array();
          childrens.push({ title: "Usuarios", to: "usuarios" });
          childrens.push({
            title: "Emular usuarios",
            to: "emular_user",
            icon: "mdi mdi-database",
          });

            childrens.push({
            title: "Solicitud Concesionario",
            to: "admin_solicitud_usuario",
            icon: "mdi mdi-database",
          });


           childrens.push({
            title: "Gestión de Grupos",
            icon: "",
            to: "GestionGrupos",
          });
          childrens.push({
            title: "Log de Errores",
            icon: "",
            to: "LogErrores",
          });
          childrens.push({
            title: "Configuraciónes",
            icon: "",
            to: "configuraciones",
          });

          this.items.push({
            title: "Administracion",
            icon: "",
            group: "/admin",
            children: childrens,
          });

          this.items.map(this.mapItem);
      }

      let childrenInformacion = Array();

      //lo comento porque los administradores aun no sabemos como se registran en SAP
      // necesitamos definir mas sobre permisos
      if ( this.$canSap("zonaDescargas")  || this.$can(["admin_general"])) {
            childrenInformacion.push({
              /*icon: "mdi mdi-download",*/
              icon: "",
              title: "Zona Descargas",
              to: "zona_archivos",
            });
            this.items.push({
              title: "Información",
              icon: "",
              group: "",
              children: childrenInformacion,
            });
             this.items.map(this.mapItem);
        }





    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    modificarTitleMenu(to, cant) {
      this.items[4].children.forEach((item) => {
        if (item.to === to) {
          //item.title = newTitle;
          item.cant = cant;
        }
      });
    },
    mensajes() {
      let cant = "";
      this.$axiosApi
        .get("mensajes_recibidos")
        .then((r) => {
          if (r.data.data.length > 0) {
            cant = r.data.data.length;
            this.modificarTitleMenu("recibidos", cant);
          }
        })
        .catch(function (error) {});
      let cantN = "";
      this.$axiosApi
        .get("noticias")
        .then((r) => {
          if (r.data.data.length > 0) {
            cantN = r.data.data.length;
            this.modificarTitleMenu("noticias", cantN);
          }
        })
        .catch(function (error) {});

      let cantE = "";
      let cont = 0;
      this.$axiosApi
        .get("mensajes_enviados")
        .then((r) => {
          if (r.data.data.length > 0) {
            r.data.data.forEach((item) => {
              if (item.estado === "enviado") {
                cont++;
              }
            });

            if (cont > 0) cantE = cont;
            this.modificarTitleMenu("enviados", cantE);
          }
        })
        .catch(function (error) {});
    },
    clearState() {

    },
  },
  watch: {
    drawer: function () {},
    '$store.state.user.sapProperties': function(newVal, oldVal) {
      if (newVal) {
        this.setNavDrawer();
      }
    }
  },
};
</script>

<style scoped>

.custom-btn {
  text-transform: none;
  font-family: inherit;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}


</style>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
